<template>
	<div class="articles">
		<div class="container">
			<gadget-ads-banner :banner="topBanner" name="bannerPageArticleList" :isBannerReady="isContentReady"></gadget-ads-banner>

			<section class="content-blog-grid mt-3 mb-2">
				<h1>บทความ/ข่าวสาร</h1>
				<div class="loading" v-if="!isResultReady">
					<div class="item">
						<loader-content :isDisplay="true"></loader-content>
					</div>
				</div>
				<template v-if="isResultReady">
					<blog-content
						:contents="resultList"
						v-if="isResultReady">
					</blog-content>
					<paging
						@page="changePage($event)"
						:activePage="activePage"
						:totalPage="totalPage"
						class="mt-4">
					</paging>
				</template>
			</section>
		</div>
	</div>
</template>

<script>
import Helper from '@/services/helper';
import ArticleService from '@/services/articleService';
import GadgetAdsBanner from '@/components/gadget/AdsBanner';
import BlogContent from '@/components/blog/BlogContent';
import RouterPath from '@/router/path';

export default {
	components: {
		GadgetAdsBanner,
		BlogContent
	},
	data() {
		return {
			isContentReady: false,
			isResultReady: false,
			topBanner: null,
			resultList: [],
			activePage: 1,
			totalPage: 0,
			routerPath: RouterPath
		};
	},
	mounted() {
        this.getContent();
		this.getArticleList();
    },
    methods: {
        async getContent() {
            this.isContentReady = false;

            const result = await ArticleService.getLandingContent();

			this.topBanner = result.data.ads;
            this.isContentReady = true;
        },
		async getArticleList() {
            this.isResultReady = false;

			const param = this.setupParamContent();
            const result = await ArticleService.getArticleList(param);

			this.resultList = result.data.resultList;
			this.totalPage = result.data.totalPage;
            this.isResultReady = true;
        },
		setupParamContent() {
			const param = {
				page: this.activePage
			};

			return param;
		},
		changePage(page) {
			this.activePage = page;
			this.getArticleList();
		}
    },
	metaInfo() {
		const title = 'บทความ/ข่าวสาร | YELLOWTiRE';
		const desc = 'รายการบทความ/ข่าวสาร YELLOWTiRE';
		const image = location.origin + Helper.metaDefault().logo;
		
        return {
            title: title,
            meta: [
				{ vmid: 'author', name: 'author', content: Helper.metaDefault().author },
				{ vmid: 'publisher', name: 'publisher', content: Helper.metaDefault().publisher },
				{ vmid: 'keyword', name: 'keywords', content: Helper.metaDefault().keyword },
                { vmid: 'desc', name: 'description', content: desc },
                { vmid: 'og-url', property: 'og:url', content: location.href },
                { vmid: 'og-title', property: 'og:title', content: title },
                { vmid: 'og-desc', property: 'og:description', content: desc },
                { vmid: 'og-type', property: 'og:type', content: 'website' },
                { vmid: 'og-image', property: 'og:image', content: image },
                { vmid: 'twitter-description', property: 'twitter:description', content: desc },
                { vmid: 'twitter-title', property: 'twitter:title', content: title },
                { vmid: 'twitter-image', property: 'twitter:image', content: image }
            ]
        }
	}
}
</script>

<style lang="scss" scoped>
@import '@/theme/Variable.scss';

.articles {
	padding: 15px 0;

	@media only screen and (max-width: $screenMedium) {
		padding-top: 0;
	}

	h1 {
		font-family: 'Open Sans', 'Kanit';
		font-weight: bold;
		font-size: 25px;
		margin-bottom: 20px;
	}
}
</style>